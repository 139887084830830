import levy from './images/projects/levycollect/1.png'
import residential from './images/projects/residential/1.png'
import inventory from './images/projects/inventory/1.png'
import exports1 from './images/projects/exports/1.png'
import simplePdf from './images/projects/simplePdf/1.png'
import lam from './images/projects/lam/1.png'
import hris from './images/projects/hris/1.png'
import deliber from './images/projects/deliber/1.png'
import hl7 from './images/projects/hl7/1.png'
import dental from './images/projects/dental/1.png'
import acudeen from './images/projects/acudeen/1.png'
import weguitar from './images/projects/weguitar/1.png'
import pentestkit from './images/projects/pentestkit/1.png'
import ecomm2 from './images/projects/ecomm/2.png'

const data = {
  name: "John Lodan Tojot",
  contactEmail: "johnlodantojot@gmail.com",
  projects: [
    {
      title: "LevyCollect Web App System",
      description: "Developed a system for a Law Firm which provides a debt recovery for outstanding strata levies, the technology we are using are VueJs and C#.net core.",
      imageSrc: levy,
      url: "http://chetanverma.com/",
      tech: ['Vue JS', 'Javascript', 'CSS', 'SASS', 'HTML', 'Bootstrap', 'C#', 'MSSQL'],
      platform: ['Web']
    },
    {
      title: "Chatbot Ordering Management System in Messenger",
      description: "Chatbot Order Management System using your messenger through mobile and web.",
      imageSrc: ecomm2,
      url: "http://chetanverma.com/",
      tech: ['React JS', 'Redux', 'Javascript', 'PHP', 'CSS', 'SASS', 'HTML', 'ANTD', 'Laravel', 'MYSQL', 'Paymongo Payment'],
      platform: ['Web', 'Mobile']
    },
    {
      title: "Residential Management Web and App System (Real Estate)",
      description: "A Residential Management System with a mobile application used to access available services and online payments, view announcements, billings, etc.",
      imageSrc: residential,
      url: require('./images/projects/residential/1.png'),
      tech: ['React JS', 'React Native', 'Redux', 'Javascript', 'CSS', 'SASS', 'HTML', 'ANTD', 'Laravel', 'MYSQL', 'DragonPay Payment', 'AQWIRE Payment'],
      platform: ['Web', 'Mobile']
    },
    {
      title: "Residential Inventory Sales Web and App System (Real Estate)",
      description: "A Residential Management System with a mobile application used by sellers to reserve a condo or property for their buyers",
      imageSrc: inventory,
      url: "http://chetanverma.com/",
      tech: ['React JS', 'React Native', 'Redux', 'Javascript', 'CSS', 'SASS', 'HTML', 'ANTD', 'Laravel', 'MYSQL'],
      platform: ['Web', 'Mobile']
    },
    {
      title: "PDF, Video and Audio Annotator",
      description: "Video and PDF Annotations App Online using ReactJS/C#, Can Add Texts, Shapes, Images and comments to an uploaded files in the system.",
      imageSrc: simplePdf,
      url: "http://chetanverma.com/",
      tech: ['React JS', 'Javascript', 'CSS', 'SASS', 'HTML', 'ANTD', 'C#'],
      platform: ['Web']
    },
    {
      title: "Epub Template Editor",
      description: "Is a Template Editor generated by Adobe InDesign Application. Which the user can upload the template into web and they can edit the details.",
      imageSrc: lam,
      url: "http://chetanverma.com/",
      tech: ['React JS', 'Redux', 'Javascript', 'CSS', 'SASS', 'HTML', 'ANTD', 'Material UI', 'C#', 'MYSQL'],
      platform: ['Web']
    },
    {
      title: "Human Resource Management Web and App System",
      description: "A Management System with a mobile application used to access available services, view announcements, personal data, pay slips, etc.",
      imageSrc: hris,
      url: "http://chetanverma.com/",
      tech: ['React JS', 'React Native', 'Redux', 'Javascript', 'CSS', 'SASS', 'HTML', 'ANTD', 'Python', 'Node', 'MongoDB'],
      platform: ['Web', 'Mobile']
    },
    {
      title: "Deliber (Deliver) App",
      description: "Order Management System for food industry using your mobile devices.",
      imageSrc: deliber,
      url: "http://chetanverma.com/",
      tech: ['Flutter', 'Dart', 'Javascript', 'CSS', 'SASS', 'HTML', 'Laravel', 'MYSQL'],
      platform: ['Web', 'Mobile']
    },
    {
      title: "Universal Robina Corporation",
      description: "Order Management System and Content Management System.",
      imageSrc: exports1,
      url: "http://chetanverma.com/",
      tech: ['React JS', 'Redux', 'Javascript', 'CSS', 'SASS', 'HTML', 'ANTD', 'Laravel', 'MYSQL'],
      platform: ['Web']
    },
    {
      title: "Medical HL7 Parser",
      description: "Cron Job for Medical HL7 file, A Medical Program that are used for scanning HL7 File changes and push it into server.",
      imageSrc: hl7,
      url: "http://chetanverma.com/",
      tech: ['Electron JS', 'Javascript', 'CSS', 'SASS', 'HTML', 'Laravel', 'MYSQL'],
      platform: ['Desktop']
    },
    {
      title: "Dental Management System",
      description: "A Dental application which where you can reserve an appointment, view your personal records and images.",
      imageSrc: dental,
      url: "http://chetanverma.com/",
      tech: ['React Native', 'React Native Paper', 'Redux', 'Javascript', 'CSS', 'SASS', 'HTML', 'Laravel', 'MYSQL'],
      platform: ['Mobile', 'Tablet']
    },
    {
      title: "Acudeen Technologies Inc.",
      description: "A Marketplace of invoices in a fintech company.",
      imageSrc: acudeen,
      url: "http://chetanverma.com/",
      tech: ['React JS', 'Redux', 'Javascript', 'CSS', 'SASS', 'HTML', 'Bootstrap', 'Python', 'Node', 'MYSQL'],
      platform: ['Web']
    },
    {
      title: "WeGuitar Mobile",
      description: "My self-project downloadable via google store and have a current 50k downloads, it has 700+ songs with a lyrics and chords.",
      imageSrc: weguitar,
      url: "http://chetanverma.com/",
      tech: ['React Native', 'Javascript', 'CSS', 'SASS', 'Native Base', 'Laravel', 'MySQL'],
      platform: ['Mobile']
    },
    {
      title: "PentestKit Mobile",
      description: "My self-project downloadable via google store and have a current 10k downloads, this tool is good for Bug Bounty Player, Penetration Testers and who wants to learn security holes and vulnerability. Features: Browser, WhoIs, Hackbar, Finder, Google Dork, Web Crawler, Phone Number Crawler, Denial of Service, Create Persistent Shells and Proxy.",
      imageSrc: pentestkit,
      url: "http://chetanverma.com/",
      tech: ['React Native', 'Javascript', 'CSS', 'SASS', 'Native Base'],
      platform: ['Mobile']
    },
  ],
}

export default data;