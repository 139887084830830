import 'antd/dist/antd.css';
import IndexPage from './pages/index'
function App() {
  return (
    <div className="App">
      <IndexPage />
    </div>
  );
}

export default App;
