import React from "react"
import Fade from "react-reveal/Fade"
import data from "../yourdata"
import { Image } from 'antd'

const Work = () => {
  return (
    <div className="section" id="work">
      <div className="container">
        <div className="work-wrapper">
          <Fade bottom>
            <h1>Projects</h1>
          </Fade>

          <div className="grid">
            <Fade bottom cascade>
              {data.projects.map((project, index) => {
                return (
                  <div className="work-items" key={index}>

                    <div className="img">
                      <Image src={project.imageSrc} alt={project.title} />
                    </div>

                    <div className="details">
                      <h1>{project.title}</h1>
                      <div className="platform">Platform:&nbsp; {
                        project.platform && project.platform.map((platform, index) => {
                          return <div key={index}><span>{platform}</span>&nbsp;</div>
                        })
                      }
                      </div>
                      <p>{project.description}</p>

                      <div className="tech">
                        {project.tech && project.tech.map((tech, index) => {
                          return (
                            <div className="tech-item" key={index}>
                              <span>{tech}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )
              })}
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Work
