import React from "react"
import reactLogo from '../images/stack/react.png'
import vueLogo from '../images/stack/vuejs.png'
import electronLogo from '../images/stack/electron.png'
import laravelLogo from '../images/stack/laravel.png'
import antdLogo from '../images/stack/antd.png'
import nodejsLogo from '../images/stack/nodejs.png'
import htmlLogo from '../images/stack/html.png'
import javascriptLogo from '../images/stack/javascript.png'
import cssLogo from '../images/stack/css.png'
import mysqlLogo from '../images/stack/mysql.png'
import mssqlLogo from '../images/stack/mssql.png'
import phpLogo from '../images/stack/php_kJuBcmu.png'
import paymongoLogo from '../images/stack/paymongo.png'
import netLogo from '../images/stack/net.png'
import bootstrapLogo from '../images/stack/bootstrap.png'
import metasploitLogo from '../images/stack/metasploit.png'
import reduxLogo from '../images/stack/redux.png'
import digitaloceanLogo from '../images/stack/digitalocean.png'
import awsLogo from '../images/stack/aws.png'
import materialuiLogo from '../images/stack/materialui.png'
import nativebaseLogo from '../images/stack/nativebase.png'
import mongodbLogo from '../images/stack/mongodb.png'
import dragonpayLogo from '../images/stack/dragonpay.png'
import aqwireLogo from '../images/stack/aqwire.png'
import Ztext from 'react-ztext'
const Skills = () => {
  const stackArray = [
    {
      label: 'React Js',
      logo: reactLogo
    },
    {
      label: 'Redux',
      logo: reduxLogo
    },
    {
      label: 'Vue Js',
      logo: vueLogo
    },
    {
      label: 'Electron Js',
      logo: electronLogo
    },
    {
      label: 'Node Js',
      logo: nodejsLogo
    },
    {
      label: 'Laravel',
      logo: laravelLogo
    },
    {
      label: 'Ant Design',
      logo: antdLogo
    },
    {
      label: 'Material UI',
      logo: materialuiLogo
    },
    {
      label: 'Native Base',
      logo: nativebaseLogo
    },
    {
      label: 'Bootstrap',
      logo: bootstrapLogo
    },
    {
      label: 'HTML',
      logo: htmlLogo
    },
    {
      label: 'CSS',
      logo: cssLogo
    },
    {
      label: 'Javascript',
      logo: javascriptLogo
    },
    {
      label: 'MySQL',
      logo: mysqlLogo
    },
    {
      label: 'MsSQL',
      logo: mssqlLogo
    },
    {
      label: 'PHP',
      logo: phpLogo
    },
    {
      label: 'C# & .net',
      logo: netLogo
    },
    {
      label: 'Mongo DB',
      logo: mongodbLogo
    },
    {
      label: 'Metasploit',
      logo: metasploitLogo
    },
    {
      label: 'Amazon AWS',
      logo: awsLogo
    },
    {
      label: 'Digital Ocean',
      logo: digitaloceanLogo
    },
    {
      label: 'Paymongo',
      logo: paymongoLogo
    },
    {
      label: 'Dragonpay',
      logo: dragonpayLogo
    },
    {
      label: 'AQwirePay',
      logo: aqwireLogo
    },
  ]
  return (
    <div className="section" id="skills">
      <div className="container">
        <div className="skills-container">
          <h1>Skills</h1>
          <div className="skills-grid">
            {
              stackArray.map((data, i) => {
                return (
                  <div style={{ display: 'inline-block', textAlign: 'center', margin: 10 }} key={i}>
                    <Ztext
                      depth='1.5rem'
                      direction='forwards'
                      event='pointer'
                      eventRotation='30deg'
                      eventDirection='default'
                      layers={10}
                      perspective='600px'
                    >
                      <span role='img' aria-label='emoji'>
                        <img width="50" height="50" src={data.logo} alt={data.logo} />
                      </span>
                    </Ztext>
                    <h1>{data.label}</h1>
                  </div>

                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Skills
